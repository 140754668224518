import React, { useEffect } from "react";
import "./styles.css"; // Assuming the CSS file is in the same directory
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NaturesBeansImageJPG from "./img/natures_beans.jpg";
import JellyBeanSunsetImage from "./img/jelly_bean_sunset_2.png";
import StickerCoupon from "./img/free_sticker.png";
import FifteenOffCoupon from "./img/fifteen_off.png";
import SaleImg from "./img/sale.png";
import TrioTrioImg from "./img/regular_trees.JPG";
import FestiveTrioTrioImg from "./img/festive_trees.JPG";

const Sale = () => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  return (
    <>
      <section className="py-5">
        <Container className="px-4 px-lg-5 mt-5">
          <Row className="gx-4 gx-lg-5 align-items-center">
            <Col xl={9} lg={10} className="mx-auto bg-light rounded p-5">
              <h2 className="section-heading mb-4">
                <span className="section-heading-upper">coupons</span>
                <span className="section-heading-lower text-lowercase">
                  Limited time holiday deals!
                </span>
              </h2>
              <p>Use these coupons at checkout to save big while supporting a small business.</p>
              <Row className="gx-4 gx-lg-5 align-items-baseline">
                <Col xl={4} lg={5} className="mx-auto bg-light rounded my-2">
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                    src={StickerCoupon}
                    alt="sticker coupon"
                  />
                  <p>Free sticker with any plushie purchase. CODE: FREESTICKER</p>
                </Col>
                <Col xl={4} lg={5} className="mx-auto bg-light rounded my-2">
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                    src={FifteenOffCoupon}
                    alt="fifteen percent off coupon"
                  />
                  <p>Take 15% off your order. CODE: 15PCTOFF</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container className="px-4 px-lg-5 mt-5">
          <Row className="gx-4 gx-lg-5 align-items-center">
            <Col xl={9} lg={10} className="mx-auto bg-light rounded p-5">
              <h2 className="section-heading mb-4">
                <span className="section-heading-upper">bean bundles</span>
                <span className="section-heading-lower text-lowercase">
                  All the beans you love at a discounted price!
                </span>
              </h2>
              <Row className="gx-4 gx-lg-5 align-items-center">
                <Col xl={4} lg={5} className="mx-auto bg-light rounded my-2">
                  <p>Nature's beans bundle - save $20!</p>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                    src={NaturesBeansImageJPG}
                    alt="natures beans"
                  />
                  <div className="text-center intro-button mx-auto mt-3">
                  <Link
                    className="btn btn-primary btn-xl"
                    to={"/products/prod_RFvfk0eFviPT4X"}
                  >
                    Shop the deal
                  </Link>
                </div>
                </Col>
                <Col xl={4} lg={5} className="mx-auto bg-light rounded my-2">
                  <p>Jelly beans bundle - save $25!</p>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                    src={JellyBeanSunsetImage}
                    alt="jelly beans"
                  />
                  <div className="text-center intro-button mx-auto mt-3">
                  <Link
                    className="btn btn-primary btn-xl"
                    to={"/products/prod_RFvwhvQu1dId1e"}
                  >
                    Shop the deal
                  </Link>
                </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container className="px-4 px-lg-5 mt-5">
          <Row className="gx-4 gx-lg-5 align-items-center">
            <Col xl={9} lg={10} className="mx-auto bg-light rounded p-5">
              <h2 className="section-heading mb-4">
                <span className="section-heading-upper">tree pillow trios</span>
                <span className="section-heading-lower text-lowercase">
                  Save big when you buy all 3 tree pillows!
                </span>
              </h2>
              <Row className="gx-4 gx-lg-5 align-items-center">
                <Col xl={4} lg={5} className="mx-auto bg-light rounded my-2">
                  <p>Regular tree pillow trio - save $20!</p>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                    src={TrioTrioImg}
                    alt="set of three tree pillows"
                  />
                  <div className="text-center intro-button mx-auto mt-3">
                  <Link
                    className="btn btn-primary btn-xl"
                    to={"/products/prod_RI7hmdMRsjAZlu"}
                  >
                    Shop the deal
                  </Link>
                </div>
                </Col>
                <Col xl={4} lg={5} className="mx-auto bg-light rounded my-2">
                  <p>Festive tree pillow trio - save $25!</p>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                    src={FestiveTrioTrioImg}
                    alt="festive set of three tree pillows"
                  />
                  <div className="text-center intro-button mx-auto mt-3">
                  <Link
                    className="btn btn-primary btn-xl"
                    to={"/products/prod_RI7tWouo1XUMqu"}
                  >
                    Shop the deal
                  </Link>
                </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="px-4 px-lg-5">
          <Row className="align-items-center justify-content-center">
            <Col xl={9} lg={10}>
              <img
                className="img-fluid align-middle"
                src={SaleImg}
                alt="blue moon bears playing in the snow"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Sale;
