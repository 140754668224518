import { useEffect } from "react";
import "./styles.css";
import ProductIcon from "./ProductIcon";

const Catalog = ({ products, cart, setCart, reviews }) => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  return (
    <>
      <section class="py-5">
        <div class="container px-4 px-lg-5 mt-5">
          <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
            {products.map((product) => (
              <ProductIcon
                product={product}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Catalog;
