import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import SuccessImg from "./img/success.png";

const Contact = () => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  return (
    <>
    <section className="py-5">
      <Container className="px-4 px-lg-5 mt-5">
        <Row className="gx-4 gx-lg-5 align-items-center">
          <Col xl={9} lg={10} className="mx-auto bg-light rounded p-5">
            <h2 className="section-heading mb-4">
              <span className="section-heading-upper">Success!</span>
              <span className="section-heading-lower text-lowercase">
                We have received your order
              </span>
            </h2>
            <p>
              Our team of blue moon bears are getting to work! Please allow two weeks for us to prepare your order and time for shipping. Thank you so much!
            </p>
          </Col>
        </Row>
      </Container>
    </section>
    <section>
    <Container className="px-4 px-lg-5">
      <Row className="align-items-center justify-content-center">
        <Col xl={9} lg={10}>
          <img
            className="img-fluid align-middle"
            src={SuccessImg}
            alt="blue moon bear logo"
          />
        </Col>
      </Row>
    </Container>
  </section>
  </>
  );
};

export default Contact;
