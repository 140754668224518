import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "./styles.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import Cart from "./Cart";
import Catalog from "./Catalog";
import Product from "./Product";
import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import BeanBackground from "./img/bean_background.JPG";
import BlueMoonBearLogo from "./img/bmb_logo_bear_only.png";
import About from "./About";
import Home from "./Home";
import Sale from "./Sale";
import Contact from "./Contact";
import Faq from "./Faq";
import Success from "./Success";
import Cancel from "./Cancel";

const CartIcon = ({ cart }) => {
  const computeCartQuantity = (cart) => {
    return cart.reduce((acc, item) => acc + item.quantity, 0);
  };
  return (
    <>
      <i className="bi-cart-fill me-1"></i>
      Cart
      <span className="badge bg-info ms-1 rounded-pill">
        {computeCartQuantity(cart)}
      </span>
    </>
  );
};

const parseCart = (cartString) => {
  try {
    const cart = JSON.parse(cartString || "[]");
    return cart.map((item) => ({
      ...item,
      quantity: Number(item.quantity),
    }));
  } catch (error) {
    console.error("Failed to parse cart:", error);
    return [];
  }
};

function App() {
  const [products, setProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [cart, setCart] = useState(parseCart(Cookies.get("cart")));

  // TODO: front back side all
  // TODO: Pagination is needed if we have more than 100 products
  useEffect(() => {
    fetch(
      "https://bmbstorageaccprod.blob.core.windows.net/productsdb/products.json"
    )
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  useEffect(() => {
    fetch("https://bmbserverprod.azurewebsites.net/api/reviews")
      .then((response) => response.json())
      .then((data) => setReviews(data.reviews))
      .catch((error) => console.error("Error fetching reviews:", error));
  }, []);

  useEffect(() => {
    // Preload catalog background image
    const image = new Image();
    image.src = BeanBackground;

    image.onload = () => {
      console.log("Image has been preloaded!");
    };
  }, []);

  return (
    <Router>
      <AppContent
        products={products}
        cart={cart}
        setCart={setCart}
        setProducts={setProducts}
        reviews={reviews}
        setReviews={setReviews}
      />
    </Router>
  );
}

function AppContent({ products, cart, setCart, reviews, setReviews }) {
  const location = useLocation();

  const bearProducts = [];
  products.forEach((otherProduct) => {
    if (
      otherProduct.tags.includes("bear") &&
      otherProduct.tags[0] !== "other"
    ) {
      bearProducts.push(otherProduct);
    }
  });

  const beanProducts = [];
  products.forEach((otherProduct) => {
    if (otherProduct.tags[0] === "bean") {
      beanProducts.push(otherProduct);
    }
  });

  const bearthstoneProducts = [];
  products.forEach((otherProduct) => {
    if (otherProduct.tags[0] === "bearthstone") {
      bearthstoneProducts.push(otherProduct);
    }
  });

  const halloweenProducts = [];
  products.forEach((otherProduct) => {
    if (otherProduct.tags[0] === "halloween") {
      halloweenProducts.push(otherProduct);
    }
  });

  const holidayProducts = [];
  products.forEach((otherProduct) => {
    if (otherProduct.tags[0] === "holiday") {
      holidayProducts.push(otherProduct);
    }
  });

  const otherProducts = [];
  products.forEach((otherProduct) => {
    if (otherProduct.tags[0] === "other") {
      otherProducts.push(otherProduct);
    }
  });

  return (
    <>
      <head>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link href="css/styles.css" rel="stylesheet" />
      </head>
      <body className="d-flex flex-column min-vh-100">
        {location.pathname !== "/catalog" && ( // Conditionally render the header
          <header>
            <h1 class="site-heading text-center text-faded d-none d-lg-block">
              <img
                className="site-heading-img align-middle"
                src={BlueMoonBearLogo}
                alt="blue moon bear logo"
              />
              <span class="site-heading-lower align-middle">
                blue moon bear
              </span>
            </h1>
          </header>
        )}
        <Navbar
          expand="lg"
          className="navbar navbar-expand-lg navbar-dark py-lg-4 sticky-top"
          id="mainNav"
        >
          <Container>
            <Navbar.Brand
              as={Link}
              to="/"
              className="text-lowercase fw-bold d-lg-none"
            >
              blue moon bear
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarSupportedContent" />
            <Navbar.Collapse id="navbarSupportedContent">
              <Nav className="mx-auto">
                <Nav.Item className="nav-item px-lg-4">
                  <Nav.Link
                    as={Link}
                    to="/"
                    className="nav-link text-lowercase"
                  >
                    Home
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="px-lg-4 dropdown">
                  <NavDropdown title="shop" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/catalog">
                      all products
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/catalog/bears">
                      bears
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/catalog/beans">
                      beans
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/catalog/andmore">
                      & more
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav.Item>
                <Nav.Item className="nav-item px-lg-4">
                  <Nav.Link as={Link} to="/sale" className="nav-link">
                    SALE
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item px-lg-4">
                  <Nav.Link
                    as={Link}
                    to="/about"
                    className="nav-link text-lowercase"
                  >
                    About
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item px-lg-4">
                  <Nav.Link
                    as={Link}
                    to="/contact"
                    className="nav-link text-lowercase"
                  >
                    Contact
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item px-lg-4">
                  <Nav.Link as={Link} to="/faq" className="nav-link">
                    FAQ
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <form className="d-flex">
                <Link className="btn btn-primary btn-lg" to="/cart">
                  <CartIcon cart={cart} />
                </Link>
              </form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <header
          className={location.pathname !== "/catalog" ? "" : "masthead"}
          style={
            location.pathname !== "/catalog"
              ? {}
              : { backgroundImage: `url(${BeanBackground})` }
          }
        >
          {location.pathname === "/catalog" && (
            <header
              className="masthead"
              style={{ backgroundImage: `url(${BeanBackground})` }}
            >
              <h1 class="display-4 fw-bolder center text-center outline">
                Shop the complete catalog
              </h1>
            </header>
          )}
        </header>
        <Routes>
          <Route
            path="/products/:productId"
            element={
              <Product
                products={products}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
                setReviews={setReviews}
              />
            }
          />
          <Route
            path="/catalog"
            element={
              <Catalog
                products={products}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
              />
            }
          />
          <Route
            path="/catalog/bears"
            element={
              <Catalog
                products={bearProducts}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
              />
            }
          />
          <Route
            path="/catalog/beans"
            element={
              <Catalog
                products={beanProducts}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
              />
            }
          />
          <Route
            path="/catalog/bearthstone"
            element={
              <Catalog
                products={bearthstoneProducts}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
              />
            }
          />
          <Route
            path="/catalog/holiday"
            element={
              <Catalog
                products={holidayProducts}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
              />
            }
          />
          <Route
            path="/catalog/halloween"
            element={
              <Catalog
                products={halloweenProducts}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
              />
            }
          />
          <Route
            path="/catalog/andmore"
            element={
              <Catalog
                products={otherProducts}
                cart={cart}
                setCart={setCart}
                reviews={reviews}
              />
            }
          />
          <Route
            path="/cart"
            element={<Cart products={products} cart={cart} setCart={setCart} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/sale" element={<Sale />} />
          <Route path="/" element={<Home products={products} />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
        <footer class="footer text-faded text-center py-5 mt-auto">
          <div class="container">
            <p class="m-0 small">&copy; 2024 blue moon bear</p>
          </div>
        </footer>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
        <script src="js/scripts.js"></script>
      </body>
    </>
  );
}

export default App;
