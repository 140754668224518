import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import CancelImg from "./img/cancel.png";
import { Link } from "react-router-dom";

const Contact = () => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  return (
    <>
      <section className="py-5">
        <Container className="px-4 px-lg-5 mt-5">
          <Row className="gx-4 gx-lg-5 align-items-center">
            <Col xl={9} lg={10} className="mx-auto bg-light rounded p-5">
              <h2 className="section-heading mb-4">
                <span className="section-heading-upper">
                  Failure to launch!
                </span>
                <span className="section-heading-lower text-lowercase">
                  It looks like you've canceled your checkout session
                </span>
              </h2>
              <p>
                Let us know if there's anything we can do to help! Get in touch
                with us <Link to="/contact">here</Link>.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="px-4 px-lg-5">
          <Row className="align-items-center justify-content-center">
            <Col xl={9} lg={10}>
              <img
                className="img-fluid align-middle"
                src={CancelImg}
                alt="blue moon bear image of smoking rocket ship"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
