import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Contact = () => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  const [email, setEmail] = useState("");
  const [messageText, setMessageText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      email,
      message: messageText,
    };

    try {
      const response = await fetch(
        "https://bmbserverprod.azurewebsites.net/api/feedback",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setEmail("");
        setMessageText("");
        setErrorMessage("");
        setIsSubmitted(true);
      } else {
        const error = await response.text();
        setErrorMessage("Error submitting message: " + error);
      }
    } catch (error) {
      setErrorMessage("Error submitting message: " + error.message);
    }
  };

  return (
    <section className="py-5">
      <Container className="px-4 px-lg-5 my-5">
        <Row className="gx-4 gx-lg-5 align-items-center">
          <Col xl={9} lg={10} className="mx-auto bg-light rounded p-5">
            <h2 className="section-heading mb-4">
              <span className="section-heading-upper">We're here for you</span>
              <span className="section-heading-lower text-lowercase">
                Contact us
              </span>
            </h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="py-2" controlId="exampleFormControlInput1">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group
                className="py-2"
                controlId="exampleFormControlTextarea1"
              >
                <Form.Label>How can we help?</Form.Label>
                <Form.Control
                  as="textarea"
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  rows={3}
                  required
                />
              </Form.Group>
              <Form.Group className="py-2">
                <Button type="submit" className="btn btn-primary btn-lg">
                  Submit
                </Button>
              </Form.Group>
            </Form>
            {isSubmitted && (
              <p>
                Your message has been received. We will get back to you as soon
                as possible! Thank you.
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
